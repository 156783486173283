import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import {
    Loading,
    Container,
    Button,
    Form,
    TextInput,
    Alert,
    Modal,
} from 'library';
import { Helmet } from 'react-helmet';

export default class PropertyTaxArticles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            loaded: false,
        };

        this.load_articles = this.load_articles.bind(this);
    }

    componentDidMount() {
        ajax_wrapper(
            'GET',
            '/api/property_tax_articles_list/',
            {},
            this.load_articles,
        );
    }

    load_articles(data) {
        console.log('articles', data);
        this.setState({ articles: data['articles'], loaded: true });
    }

    replace_blanks(text) {
        let new_text = text.replaceAll(' ', '_');
        return new_text;
    }

    render() {
        let article_list = [];
        for (let article of this.state.articles) {
            let link =
                '/property_tax_guide/' +
                article.state +
                '/' +
                this.replace_blanks(article.county) +
                '/' +
                this.replace_blanks(article.city) +
                '/';
            article_list.push(
                <div key={article.id}>
                    <a href={link} target="_blank">
                        {article.city},{article.state}
                    </a>
                </div>,
            );
        }

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>A Collection Of Property Tax Articles</title>
                    <meta
                        name="description"
                        content="Find An Article About Property Taxes In Your Area Below."
                    />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <h2 style={{ marginTop: '40px' }}>
                        Find An Article About Property Taxes In Your Area Below.
                    </h2>
                    {article_list}
                </Loading>
            </Container>
        );
    }
}
