import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button } from 'library';
import { Helmet } from 'react-helmet';
import { AddressSearch, SignUpModal } from 'components';

export default class PropertyTaxArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            article: { html: '' },
            property: {},
            loaded: false,
        };

        this.load_article = this.load_article.bind(this);
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        let state = params['state'];
        let county = params['county'];
        let city = params['city'];
        ajax_wrapper(
            'GET',
            '/api/property_tax_articles/',
            { state: state, county: county, city: city },
            this.load_article,
        );
    }

    load_article(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    select_property = (property) => {
        this.setState({ property: property });

        if (window.secret_react_state.user) {
            window.location.href = '/property_opportunities/' + property.id;
        }
    };

    render() {
        let params = get_global_state()['params'];
        let state = params['state'];
        let county = params['county'];
        let city = params['city'];

        let article = this.state.article.html;
        let htmlContentStart = article.indexOf('htmlContent": `');
        if (htmlContentStart !== -1) {
            htmlContentStart += 'htmlContent": `'.length;
            let htmlContentEnd = article.indexOf('`', htmlContentStart);
            if (htmlContentEnd !== -1) {
                article = article.substring(htmlContentStart, htmlContentEnd);
            }
        }
        article = article.replace(
            /yourhomebase\.co/gi,
            "<a href='https://yourhomebase.co/' target='_blank'>HomeBase</a>",
        );

        let address_search_form = (
            <AddressSearch
                input_class="gradient-border-inverted thick"
                select_property={this.select_property}
            />
        );

        return (
            <Container>
                <Helmet>
                    <title>
                        {city}, {state} Property Tax Assessments, are you
                        overpaying?
                    </title>
                    <meta
                        name="description"
                        content="A guide to property tax assessments in {city}, {state}. Learn how to check if you're overpaying and what to do about it."
                    />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '20px',
                            pointer: 'cursor',
                        }}
                    >
                        <Button className="gradient-border" href="#check-form">
                            <div
                                className="gradient-border-content"
                                style={{ fontWeight: 500, fontSize: '1.75em' }}
                            >
                                Check If I'm Overpaying Now!
                            </div>
                        </Button>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: article,
                        }}
                    ></div>
                    <div style={{ margin: '50px', textAlign: 'center' }}>
                        <a id="check-form"></a>
                        <h2>
                            Enter your address below and we'll tell you if you
                            are overpaying in seconds!
                        </h2>
                        {address_search_form}
                    </div>
                    <SignUpModal
                        property={this.state.property}
                        close={() => this.setState({ property: {} })}
                    />
                </Loading>
            </Container>
        );
    }
}
